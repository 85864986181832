import React from 'react';
import { Wallet, Users, Building2, UserPlus, HandshakeIcon, UserCheck } from 'lucide-react';

interface CommissionMember {
  reason: string;
  amount: number;
  date: string;
  uid: string;
  assetid_soldout: string;
}

interface CommissionData {
  listMember: CommissionMember[];
}

interface CommissionSummaryProps {
  data: CommissionData;
  profit: number;
}

interface SummaryItem {
  title: string;
  amount: number;
  icon: React.ReactNode;
  bgColor: string;
  textColor: string;
  borderColor: string;
  excludeFromTotal?: boolean;
}

interface Totals {
  uplineShare: number;
  uplineInvite: number;
  uplineSell: number;  // Added new type
  sellSuccess: number;
  buySuccess: number;
  companyProfit: number;
}

const CommissionSummary: React.FC<CommissionSummaryProps> = ({ data, profit }) => {
  const calculateTotal = (reason: string): number => {
    return data.listMember
      .filter(item => item.reason === reason)
      .reduce((sum, item) => sum + item.amount, 0);
  };

  const totals: Totals = {
    uplineShare: calculateTotal('upline สายแชร์'),
    uplineInvite: calculateTotal('upline สายชวน'),
    uplineSell: calculateTotal('upline ชวนขาย'),  // Added new calculation
    sellSuccess: calculateTotal('ชวนขายสำเร็จ'),
    buySuccess: calculateTotal('ชวนซื้อสำเร็จ'),
    companyProfit: profit
  };

  const formatCurrency = (amount: number): string => {
    return new Intl.NumberFormat('th-TH', {
      style: 'currency',
      currency: 'THB',
      minimumFractionDigits: 0,
      maximumFractionDigits: 1
    }).format(amount);
  };

  const summaryItems: SummaryItem[] = [
    {
      title: 'ชวนขายสำเร็จ',
      amount: totals.sellSuccess,
      icon: <UserPlus className="h-6 w-6" />,
      bgColor: 'bg-orange-50',
      textColor: 'text-orange-700',
      borderColor: 'border-orange-200'
    },
    {
      title: 'ชวนซื้อสำเร็จ',
      amount: totals.buySuccess,
      icon: <HandshakeIcon className="h-6 w-6" />,
      bgColor: 'bg-purple-50',
      textColor: 'text-purple-700',
      borderColor: 'border-purple-200'
    },
    {
      title: 'Upline สายชวน',
      amount: totals.uplineInvite,
      icon: <Users className="h-6 w-6" />,
      bgColor: 'bg-green-50',
      textColor: 'text-green-700',
      borderColor: 'border-green-200'
    },
    {
      title: 'Upline สายแชร์',
      amount: totals.uplineShare,
      icon: <Wallet className="h-6 w-6" />,
      bgColor: 'bg-pink-50',
      textColor: 'text-pink-700',
      borderColor: 'border-pink-200'
    },
    {
      title: 'Upline ชวนขาย',  // New category
      amount: totals.uplineSell,
      icon: <UserCheck className="h-6 w-6" />,
      bgColor: 'bg-blue-50',
      textColor: 'text-blue-700',
      borderColor: 'border-blue-200'
    },
    {
      title: 'กำไรบริษัท',
      amount: totals.companyProfit,
      icon: <Building2 className="h-6 w-6" />,
      bgColor: 'bg-rose-50',
      textColor: 'text-rose-700',
      borderColor: 'border-rose-200',
      excludeFromTotal: true
    }
  ];

  // Calculate total excluding company profit
  const RemainingTotalExpense2in3: number = summaryItems
    .filter(item => !item.excludeFromTotal)
    .reduce((sum, item) => sum + item.amount, 0);

  // Calculate percentage helper function (only for included items)
  const calculatePercentage = (item: SummaryItem): string => {
    if (item.excludeFromTotal) {
      return '-';
    }
    return ((item.amount / RemainingTotalExpense2in3) * 100).toFixed(3);
  };

  return (
    <div className="w-full max-w-5xl mx-auto p-4 space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {summaryItems.map((item, index) => (
          <div
            key={index}
            className={`${item.bgColor} ${item.borderColor} border rounded-lg p-4 transition-transform duration-200 hover:scale-105`}
          >
            <div className="flex items-center justify-between mb-2">
              <div className={`${item.textColor}`}>
                {item.icon}
              </div>
              <span className="text-sm font-medium text-gray-600">
                {calculatePercentage(item)}%
              </span>
            </div>
            <h3 className="text-lg font-semibold text-gray-800 mb-1">
              {item.title}
            </h3>
            <p className={`text-xl font-bold ${item.textColor}`}>
              {formatCurrency(item.amount)}
            </p>
          </div>
        ))}
      </div>
   
      <div className="bg-gray-50 border border-gray-200 rounded-lg p-4">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-semibold text-gray-800">ยอดรวมค้างจ่าย</h3>
          <p className="text-2xl font-bold text-red-500">
            {formatCurrency(RemainingTotalExpense2in3)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CommissionSummary;